export default [
  {
    title: "animals",
    route: "animals",
    resource: "Auth",
    action: "read",
    src: require("@/assets/images/icons/beef.svg"),
  },
  {
    src: require("@/assets/images/icons/milk.svg"),
    title: "milkingList",
    route: "animals-milking",
    resource: "Beef",
    action: "read",
  },
  {
    src: require("@/assets/images/icons/beef.svg"),
    title: "animals-weights-conditions",
    route: "animals-weights-conditions",
    resource: "Beef",
    action: "read",
  },
  {
    src: require("@/assets/images/icons/ingredients.svg"),
    title: "feedingList",
    route: "animals-feeding",
    resource: "Auth",
    action: "read",
  },
  {
    src: require("@/assets/images/icons/beef.svg"),
    title: "slaughterhouses",
    route: "slaughterhouses",
    resource: "Auth",
    action: "read",
  },
];
