export default [
  {
    title: "rations",
    resource: "Auth",
    action: "read",
    src: require("@/assets/images/icons/optimizeW.svg"),

    children: [
      {
        src: require("@/assets/images/icons/milk.svg"),
        title: "dairyRations",
        route: {
          name: "plantRations",
          params: { tur: "dairy" },
        },
        resource: "Auth",
        action: "read",
        exact: true,
      },
      {
        src: require("@/assets/images/icons/beef.svg"),
        title: "beefRations",
        route: {
          name: "plantRations",
          params: { tur: "beef" },
        },
        resource: "Auth",
        action: "read",
        exact: true,
      },
      {
        src: require("@/assets/images/icons/beef.svg"),
        title: "productionRations",
        route: {
          name: "productionRations",
        },
        resource: "Auth",
        action: "read",
        exact: true,
      },
      {
        src: require("@/assets/images/icons/ingredients.svg"),
        title: "ingredients",
        route: { name: "ingredients" },
        resource: "Auth",
        action: "read",
        exact: true,
      },
      {
        src: require("@/assets/images/icons/products.svg"),
        title: "nutrients",
        route: { name: "nutrients" },
        resource: "Auth",
        action: "read",
        exact: true,
      },
      {
        src: require("@/assets/images/icons/prices.svg"),
        title: "ingredientPrices",
        route: { name: "ingredientsPrices" },
        resource: "Auth",
        action: "read",
        exact: true,
      },
    ],
  },
];
