import animals from "./animals";
import companySettings from "./company";
import rations from "./rations";
import plants from "./plants";
export default [
  {
    icon: "AirplayIcon",
    title: "homePage",
    route: "dashboard",
    resource: "Auth",
    action: "read",
    exact: true,
  },
  ...animals,

  ...rations,
  {
    icon: "CircleIcon",
    title: "notes",
    route: "notes",
    resource: "Auth",
    action: "read",
    exact: true,
  },
  ...plants,
  ...companySettings,
];
