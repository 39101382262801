import definitions from "./definitions";
export default [
  {
    title: "settings",
    resource: "Auth",
    action: "read",
    icon: "SettingsIcon",

    children: [
      {
        src: require("@/assets/images/icons/settings.svg"),

        title: "generalSettings",
        route: "general-settings",
        resource: "Auth",
        action: "read",
      },
      {
        src: require("@/assets/images/icons/users.svg"),
        title: "users",
        route: "users",
        resource: "Auth",
        action: "read",
        exact: true,
      },
      {
        src: require("@/assets/images/icons/modules.svg"),
        title: "modules",
        route: "modules",
        resource: "Auth",
        action: "read",
        exact: true,
      },
      ...definitions,

      // {
      //   src: require("@/assets/images/icons/term.svg"),
      //   title: "termsInfo",
      //   route: "terms",
      //   resource: "Auth",
      //   action: "read",
      //   exact: true,
      // },
      // {
      //   src: require("@/assets/images/icons/ingredients.svg"),
      //   title: "companyProducts",
      //   route: "companyProduct",
      //   resource: "Auth",
      //   action: "read",
      //   exact: true,
      // },
      // {
      //   src: require("@/assets/images/icons/prices.svg"),
      //   title: "prices",
      //   route: "prices",
      //   resource: "Auth",
      //   action: "read",
      //   exact: true,
      // },
      // {
      //   src: require("@/assets/images/icons/visits.svg"),
      //   title: "visits",
      //   route: "visits",
      //   resource: "Auth",
      //   action: "read",
      //   exact: true,
      // },
      // {
      //   src: require("@/assets/images/icons/orders.svg"),
      //   title: "orders",
      //   route: "orders",
      //   params: "plantid",
      //   resource: "Auth",
      //   action: "read",
      //   exact: true,
      // },
    ],
  },
];
