export default [
  {
    title: "definitions",
    resource: "Auth",
    action: "read",
    icon: "SettingsIcon",
    route: "definitions",

    // children: [
    //   {
    //     icon: "CircleIcon",
    //     title: "treatments",
    //     route: "treatments",
    //     resource: "Auth",
    //     action: "read",
    //     exact: true,
    //   },
    //   {
    //     icon: "CircleIcon",
    //     title: "treatmentTypes",
    //     route: "treatmentTypes",
    //     resource: "Auth",
    //     action: "read",
    //     exact: true,
    //   },
    //   {
    //     icon: "CircleIcon",
    //     title: "spermas",
    //     route: "spermas",
    //     resource: "Auth",
    //     action: "read",
    //     exact: true,
    //   },

    //   {
    //     icon: "CircleIcon",
    //     title: "exams",
    //     route: "exams",
    //     resource: "Auth",
    //     action: "read",
    //     exact: true,
    //   },
    //   {
    //     icon: "CircleIcon",
    //     title: "vets",
    //     route: "vets",
    //     resource: "Auth",
    //     action: "read",
    //     exact: true,
    //   },
    //   {
    //     icon: "CircleIcon",
    //     title: "medicinesAndVaccines",
    //     route: "medicines",
    //     resource: "Auth",
    //     action: "read",
    //     exact: true,
    //   },
    //   {
    //     icon: "CircleIcon",
    //     title: "illnesses",
    //     route: "illnesses",
    //     resource: "Auth",
    //     action: "read",
    //     exact: true,
    //   },
    //   {
    //     icon: "CircleIcon",
    //     title: "illnessesTypes",
    //     route: "illnessesTypes",
    //     resource: "Auth",
    //     action: "read",
    //     exact: true,
    //   },
    //   {
    //     icon: "CircleIcon",
    //     title: "barns",
    //     route: "barns",
    //     resource: "Auth",
    //     action: "read",
    //     exact: true,
    //   },
    //   {
    //     icon: "CircleIcon",
    //     title: "parameters",
    //     route: "parameters",
    //     resource: "Auth",
    //     action: "read",
    //     exact: true,
    //   },
    //   {
    //     icon: "CircleIcon",
    //     title: "synchronizations",
    //     route: "synchronizations",
    //     resource: "Auth",
    //     action: "read",
    //     exact: true,
    //   },
    //   {
    //     icon: "CircleIcon",
    //     title: "machines",
    //     route: { name: "machines" },
    //     resource: "Auth",
    //     action: "read",
    //     exact: true,
    //   },
    // ],
  },
];
