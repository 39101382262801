export default [
  {
    src: require("@/assets/images/icons/plants.svg"),
    title: "plants",
    route: "plants",
    resource: "Auth",
    action: "read",
    exact: true,
  },
];
